import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { Suspense, lazy } from "react";
import { Route, Redirect } from "react-router";
// import Home from "../controllers/Home";
// import Carreras from "../controllers/Carreras";
// import Contacto from "../controllers/Contacto";
// import ListaGalerias from "../controllers/ListaGalerias";
// import Galeria from "../controllers/Galeria";
// import ListaNoticias from "../controllers/ListaNoticias";
// import Noticia from "../controllers/Noticia";
// import Trayectoria from "../controllers/Trayectoria";
import Menu from "../components/Menu";
import SuspenseLoading from "../components/SuspenseLoading";
const Home = lazy(() => import("../controllers/Home"));
const Trayectoria = lazy(() => import("../controllers/Trayectoria"));
const Carreras = lazy(() => import("../controllers/Carreras"));
const Contacto = lazy(() => import("../controllers/Contacto"));
const ListaGalerias = lazy(() => import("../controllers/ListaGalerias"));
const Galeria = lazy(() => import("../controllers/Galeria"));
const ListaNoticias = lazy(() => import("../controllers/ListaNoticias"));
const Noticia = lazy(() => import("../controllers/Noticia"));

const AppUI: React.FC = () => (
  <React.Fragment>
    <IonReactRouter>
      <Menu />
      <IonRouterOutlet id="main">
        <Suspense fallback={<SuspenseLoading />}>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/noticias">
            <ListaNoticias />
          </Route>
          <Route exact path="/trayectoria">
            <Trayectoria />
          </Route>
          <Route exact path="/carreras">
            <Carreras />
          </Route>
          <Route exact path="/galeria">
            <ListaGalerias />
          </Route>
          <Route path="/galeria/:id" exact>
            <Galeria />
          </Route>
          <Route path="/noticias/:id" exact>
            <Noticia />
          </Route>
          <Route exact path="/contacto">
            <Contacto />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </Suspense>
      </IonRouterOutlet>
    </IonReactRouter>
  </React.Fragment>
);

export default AppUI;
