import { useState } from "react";
import "../theme/custom-css/suspense-loading.css"
import { useLogoEncabezado } from "../hooks/useLogoEncabezado";

const SuspenseLoading: React.FC = () => {
    const [ logo, setLogo ] = useState<string>(require("../assets/home/logo-bonelli.png"));
  
    useLogoEncabezado().then((res) => {
      setLogo(res);
    });
    
    return (
        <div className="suspense-wrapper">
            <img src={logo} alt="" className="suspense-logo" />
        </div>
    );
};

export default SuspenseLoading;