import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
} from "@ionic/react";
import "../theme/custom-css/navbar.css";
import { useLogoEncabezado } from "../hooks/useLogoEncabezado";
import { useState } from "react";

const Menu = () => {
  const [ logo, setLogo ] = useState<string>(require("../assets/home/logo-bonelli.png"));

  useLogoEncabezado().then((res) => {
    setLogo(res);
  });
  
  return (
    <IonMenu side="end" contentId="main" swipe-gesture={false}>
      <IonHeader>
        <IonToolbar className="ion-text-center">
          <div className="logo-frame">
            <img
              src={logo}
              alt=""
              className="logo"
            />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="none" className="menu-list">
          <IonMenuToggle autoHide={false}>
            <IonItem
              className="titulo nav-link"
              button
              routerLink={"/home"}
              routerDirection="none"
            >
              <IonLabel>INICIO</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className="titulo nav-link"
              button
              routerLink={"/trayectoria"}
              routerDirection="none"
            >
              <IonLabel>TRAYECTORIA</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className="titulo nav-link"
              button
              routerLink={"/carreras"}
              routerDirection="none"
            >
              <IonLabel>RESULTADOS</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className="titulo nav-link"
              button
              routerLink={"/galeria"}
              routerDirection="none"
            >
              <IonLabel>GALERÍA</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className="titulo nav-link"
              button
              routerLink={"/noticias"}
              routerDirection="none"
            >
              <IonLabel>NOTICIAS</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className="titulo nav-link"
              button
              routerLink={"/contacto"}
              routerDirection="none"
            >
              <IonLabel>CONTACTO</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
